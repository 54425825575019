import {Client} from '@/models';
import {AbstractRequest} from '@/services/abstractService';

class AuthService extends AbstractRequest {

  public login(apiKey: string) {
    this.endpoint = `/auth/login`;
    this.data = {
      APIKey: apiKey,
    };
    return this.post<Client>();
  }
}

export const authService = new AuthService();
