



















import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {
  private authenticated = false;

  private mounted() {
    this.$root.$on('login', () => {
      this.authenticated = true;
    });
    this.$root.$on('logout', () => {
      this.authenticated = false;
    });
  }
}
