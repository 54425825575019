import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Login from '@/views/Login.vue';
import JobsList from '@/views/JobsList.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    }, {
      path: '/login',
      name: 'login',
      component: Login,
    }, {
      path: '/jobs',
      name: 'jobsList',
      component: JobsList,
    },
  ],
});
