import Vue from 'vue';
import TimeAgo from 'javascript-time-ago';
import fr from 'javascript-time-ago/locale/fr';

TimeAgo.addLocale(fr);
const timeAgo = new TimeAgo('fr-FR');

Vue.filter('fullDate', (date: Date) => {
  const dateOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
  };
  const timeOptions = {
    hour: '2-digit', minute: '2-digit', hour12: false,
  };
  const newDate = new Date(date);
  return `${newDate.toLocaleTimeString('fr-FR', timeOptions)} le \
    ${newDate.toLocaleDateString('fr-FR', dateOptions)}`;
});

Vue.filter('timeAgo', (date: Date) => {
  return timeAgo.format(new Date(date).getTime());
});

Vue.filter('sec', (value: string) => Math.round(parseInt(value, 10) / 100) / 10);

Vue.filter('capitalize', (value: string) => {
  if (!value) {
    return '';
  }
  const newValue = value.toString();
  return newValue.charAt(0).toUpperCase() + newValue.slice(1);
});
