
















import { Component, Vue } from 'vue-property-decorator';
import {socketService} from '@/services/socket';
import {SOCKET_EVENTS} from '@/models/socketIo';
import {Client} from '@/models';

@Component({})
export default class Toolbar extends Vue {
  private authenticated = false;
  private client: Client | null = null;

  private toggleMenu() {
    this.$root.$emit('toggleMenu');
  }

  private logout() {
    delete localStorage.client;
    delete localStorage.token;
    socketService.emit(SOCKET_EVENTS.USER_LOGOUT);
    this.$root.$emit('logout');
    this.$router.push('/');
  }

  private mounted() {
    if (localStorage.token) {
      this.authenticated = true;
      this.client = JSON.parse(localStorage.client);
    }
    this.$root.$on('login', () => {
      this.authenticated = true;
      this.client = JSON.parse(localStorage.client);
    });
    this.$root.$on('logout', () => {
      this.authenticated = false;
    });
  }
}
