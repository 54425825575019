























































































































































































import {Component, Vue} from 'vue-property-decorator';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import ManualFiltersDialog from '@/components/ManualFiltersDialog.vue';
import {jobService} from '@/services/job';
import {DEFAULT_PER_PAGE, Filters, Job, JOB_STATUS, PaginationParams, SnackbarStatus} from '@/models';
import {socketService} from '@/services/socket';
import {SOCKET_EVENTS} from '@/models/socketIo';
import ConfirmationInformationDialog from '@/components/ConfirmationInformationDialog.vue';
import Loader from '@/components/Loader.vue';

@Component<JobsList>({
  components: {
    ConfirmationInformationDialog,
    InfiniteScroll,
    ManualFiltersDialog,
    Loader,
  },
})
export default class JobsList extends Vue {
  private authenticated = false;
  private jobs: Job[] = [];
  private page = 1;
  private filters: Filters = {};
  private manualFilterDialog = false;
  private confirmationInformationDialog = false;
  private callbackToBeConfirm: (() => {}) | null = null;
  private editJobId: string | null = null;
  private loading = false;
  private paginationParams: PaginationParams = {
    page: 1,
    limit: DEFAULT_PER_PAGE.JOB,
  };
  private lastPage: boolean = false;
  private panel: number | null = null;

  private handleManualFilter(data: any) {
    this.manualFilterDialog = false;
    if (data) {
      switch (data.type) {
        case 'Tag':
          this.addTag(data.value);
          break;
        case 'Type':
          this.addType(data.value);
          break;
        case 'Statut':
          this.addStatus(data.value);
          break;
        default:
          return;
      }
    }
  }

  private openConfirmationInformationDialog(callback: (() => {})) {
    this.callbackToBeConfirm = callback;
    this.confirmationInformationDialog = true;
  }

  private handleConfirmationInformation(response: boolean) {
    this.confirmationInformationDialog = false;
    if (response && this.callbackToBeConfirm) {
      this.callbackToBeConfirm();
    }
  }

  private async addTag(tag: string) {
    if (!this.filters.tags) {
      this.filters.tags = [];
    }
    if (!this.filters.tags.includes(tag)) {
      this.filters.tags.push(tag);
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private async removeTag(tag: string) {
    if (this.filters.tags && this.filters.tags.includes(tag)) {
      this.filters.tags.splice(this.filters.tags.indexOf(tag), 1);
      if (this.filters.tags.length === 0) {
        delete this.filters.tags;
      }
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private async addStatus(status: string) {
    if (!this.filters.status) {
      this.filters.status = [];
    }
    if (this.filters.status && !this.filters.status.includes(status)) {
      this.filters.status.push(status);
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private async removeStatus(status: string) {
    if (this.filters.status && this.filters.status.includes(status)) {
      this.filters.status.splice(this.filters.status.indexOf(status), 1);
      if (this.filters.status.length === 0) {
        delete this.filters.status;
      }
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private async addType(type: string) {
    if (!this.filters.types) {
      this.filters.types = [];
    }
    if (this.filters.types && !this.filters.types.includes(type)) {
      this.filters.types.push(type);
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private async removeType(type: string) {
    if (this.filters.types && this.filters.types.includes(type)) {
      this.filters.types.splice(this.filters.types.indexOf(type), 1);
      if (this.filters.types.length === 0) {
        delete this.filters.types;
      }
      this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
    }
  }

  private removeId() {
    delete this.filters.id;
    this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
  }

  private async reverseOrder() {
    if (this.filters.reverse) {
      delete this.filters.reverse;
    } else {
      this.filters.reverse = true;
    }
    this.$router.push({ path: 'jobs', query: this.formatFiltersAsURLParams() });
  }

  private formatFiltersAsURLParams() {
    if (Object.keys(this.filters).length === 1 && this.filters.id) {
      return {
        id: this.filters.id,
      };
    }
    return {
      tags: this.filters.tags ? this.filters.tags.join(',') : '',
      status: this.filters.status ? this.filters.status.join(',') : '',
      types: this.filters.types ? this.filters.types.join(',') : '',
      reverse: this.filters.reverse ? 'true' : 'false',
    };
  }

  private getFiltersFromUrl() {
    Object.keys(this.$route.query).forEach((queryParam) => {
      switch (queryParam) {
        case 'id':
          if (this.$route.query.id) {
            this.filters.id = this.$route.query.id as string;
          }
          break;
        case 'tags':
          if (this.$route.query.tags !== '') {
            this.filters.tags = (this.$route.query.tags as string).split(',');
          }
          break;
        case 'status':
          if (this.$route.query.status !== '') {
            this.filters.status = (this.$route.query.status as string).split(',');
          }
          break;
        case 'types':
          if (this.$route.query.types !== '') {
            this.filters.types = (this.$route.query.types as string).split(',');
          }
          break;
        case 'reverse':
          if (this.$route.query.reverse === 'true') {
            this.filters.reverse = true;
          }
          break;
      }
    });
  }

  private copyUrlToClipboard() {
    const dummy = document.createElement('input');
    const url = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.$root.$emit('showSnackbar', 'URL copiée dans le presse papier', SnackbarStatus.INFO);
  }

  private async getMore() {
    if (!this.lastPage && !this.loading) {
      this.getFiltersFromUrl();
      if (this.filters.id && this.filters.id !== '') {
        try {
          this.loading = !this.loading;
          const job = await jobService.getJob(this.filters.id);
          this.jobs.push(job);
          this.lastPage = true;
          this.loading = !this.loading;
        } catch (e) {
          this.$root.$emit('showSnackbar', 'Erreur dans la récupération du job', SnackbarStatus.ERROR);
          throw e;
        }
      } else {
        try {
          this.loading = !this.loading;
          const newJobs = await jobService.getJobs(this.paginationParams, this.filters);
          if (newJobs.items && newJobs.items.length) {
            this.jobs.push(...newJobs.items);
            this.lastPage = this.paginationParams.page >= newJobs.totalPage;
            this.paginationParams.page += 1;
          }
          this.loading = !this.loading;
        } catch (e) {
          this.$root.$emit('showSnackbar', 'Erreur dans la récupération des jobs', SnackbarStatus.ERROR);
          throw e;
        }
      }
      if (this.jobs.length === 1) {
        this.panel = 0;
      }
    }
  }

  private getColorByStatus(status: JOB_STATUS) {
    switch (status) {
      case JOB_STATUS.NEW:
        return 'primary';
      case JOB_STATUS.CANCELED:
        return 'grey';
      case JOB_STATUS.PENDING:
        return 'orange';
      case JOB_STATUS.DONE:
        return 'green';
      case JOB_STATUS.FAILED:
        return 'error';
    }
  }

  private prettifyJSON(data: string) {
    try {
      return JSON.stringify(JSON.parse(data ? data : '{}'), null, 2);
    } catch {
      return data;
    }
  }

  private async restartJob(job: Job) {
    const oldStatus = job.status;
    try {
      job.status = JOB_STATUS.NEW;
      await jobService.update(job);
      this.$root.$emit('showSnackbar', 'Le job a bien été redémarré', SnackbarStatus.SUCCESS);
    } catch (e) {
      job.status = oldStatus;
      this.$root.$emit('showSnackbar', 'Erreur dans le restart du job', SnackbarStatus.ERROR);
      throw e;
    }
  }

  private async cancelJob(job: Job) {
    const oldStatus = job.status;
    try {
      job.status = JOB_STATUS.CANCELED;
      await jobService.update(job);
      this.$root.$emit('showSnackbar', 'Le job a bien été annulé', SnackbarStatus.SUCCESS);
    } catch (e) {
      job.status = oldStatus;
      this.$root.$emit('showSnackbar', 'Erreur dans le changement de status du job', SnackbarStatus.ERROR);
      throw e;
    }
  }

  private async editJob(job: Job) {
    this.editJobId = null;
    try {
      await jobService.update(job);
      this.$root.$emit('showSnackbar', 'Le job a bien été édité', SnackbarStatus.SUCCESS);
    } catch (e) {
      this.$root.$emit('showSnackbar', 'Erreur dans la mise à jour du job', SnackbarStatus.ERROR);
      this.jobs = [];
      await this.getMore();
      throw e;
    }
  }

  private async mounted() {
    await this.getMore();
    this.$root.$on('login', () => {
      this.authenticated = true;
    });
    this.$root.$on('logout', () => {
      this.authenticated = false;
    });
    socketService.on(SOCKET_EVENTS.NEW_JOB, (job: Job) => {
      if (Object.keys(this.filters).length === 0) {
        this.jobs.unshift(job);
      } else {
        this.$root.$emit('showSnackbar', 'Nouveau job enregistré sur l`API.' +
          ' Supprimer vos filtres pour le voir.', SnackbarStatus.INFO);
      }
    });
    socketService.on(SOCKET_EVENTS.UPDATED_JOB, (job: Job) => {
      const index = this.jobs.findIndex((j) => j.id === job.id);
      if (index >= 0) {
        this.jobs[index] = job;
        this.$forceUpdate();
      }
    });
  }
}
