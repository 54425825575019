


















import {Component, Vue} from 'vue-property-decorator';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
import Toolbar from '@/components/Toolbar.vue';
import Snackbar from '@/components/Snackbar.vue';
import {authService} from '@/services/auth';
import {SnackbarStatus} from '@/models';
import {socketService} from '@/services/socket';
import {SOCKET_EVENTS} from '@/models/socketIo';

@Component({
  components: {
    Menu,
    Toolbar,
    Footer,
    Snackbar,
  },
})
export default class App extends Vue {
  private async mounted() {
    if (localStorage.token) {
      try {
        const client = await authService.login(localStorage.token);
        socketService.emit(SOCKET_EVENTS.USER_LOGIN, { APIKey: localStorage.token });
        this.$root.$emit('login', client);
      } catch (e) {
        delete localStorage.token;
        this.$root.$emit('showSnackbar', 'Erreur dans durant l\'authentification automatique.', SnackbarStatus.ERROR);
      }
    }
  }
}
