
import {Filters, Job, PaginationResponse} from '@/models';
import { AbstractRequest } from '@/services/abstractService';
import {PaginationParams} from '@/models';

class JobService extends AbstractRequest {

  public getJobs(paginationParams: PaginationParams, filters: Filters) {
    this.endpoint = `/jobs`;
    this.params = {
      ...paginationParams,
    };
    if (filters.tags) {
      this.params.tags = filters.tags.join(',');
    }
    if (filters.status) {
      this.params.status = filters.status.join(',');
    }
    if (filters.types) {
      this.params.types = filters.types.join(',');
    }
    if (filters.reverse !== undefined) {
      this.params.reverse = filters.reverse;
    }
    this.authed = true;
    return this.get<PaginationResponse<Job>> ();
  }

  public getJob(id: string) {
    this.authed = true;
    this.endpoint = `/jobs/${id}`;
    return this.get<Job>();
  }

  public update(job: Job) {
    this.endpoint = `/jobs/${job.id}`;
    this.authed = true;
    this.data = {
      status: job.status,
      tags: job.tags,
      data: job.data,
    };
    return this.put<Job>();
  }
}

export const jobService = new JobService();
