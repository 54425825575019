import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router/router';
import './filters/filters';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
