export interface Job {
  id: string;
  type: string;
  status: JOB_STATUS;
  data: string;
  executionLogs: string;
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
}

export enum JOB_STATUS {
  'NEW' = 'NEW',
  'CANCELED' = 'CANCELED',
  'FAILED' = 'FAILED',
  'PENDING' = 'PENDING',
  'DONE' = 'DONE',
}

export interface Filters {
  tags?: string[];
  status?: string[];
  types?: string[];
  reverse?: boolean;
  id?: string;
}
