















import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class InfiniteScroll extends Vue {

  private readonly PIXELS_BEFORE_BOTTOM = 10;
  private showButton = false;
  private bottom: boolean = this.bottomVisible();

  private bottomVisible(): boolean {
    const scrollY = window.scrollY;
    const visible = document.documentElement.clientHeight;
    const pageHeight = document.documentElement.scrollHeight;
    const bottomOfPage = visible + scrollY >= pageHeight - this.PIXELS_BEFORE_BOTTOM;
    return bottomOfPage || pageHeight < visible;
  }

  @Watch('bottom')
  private async bottomWatcher(isBottom: boolean) {
    if (isBottom) {
      this.$emit('bottom');
    }
  }

  private async mounted() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
      this.showButton = window.scrollY > 50;
    });
  }
}
