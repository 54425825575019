























import { Component, Vue } from 'vue-property-decorator';

interface MenuItem {
  name: string;
  path: string;
  auth: boolean;
  icon: string;
}

@Component
export default class Menu extends Vue {
  public drawer = false;
  private authenticated = false;

  private menuItems: MenuItem[] = [
    {
      name: 'Accueil',
      path: '/',
      auth: false,
      icon: 'home',
    }, {
      name: 'Liste des jobs',
      path: '/jobs',
      auth: true,
      icon: 'view_list',
    },
  ];

  private mounted() {
    this.$root.$on('toggleMenu', () => {
      this.drawer = !this.drawer;
    });
    this.$root.$on('login', () => {
      this.authenticated = true;
    });
    this.$root.$on('logout', () => {
      this.authenticated = false;
    });
  }
}

