





























import {Component, Vue} from 'vue-property-decorator';
import {authService} from '@/services/auth';
import {SnackbarStatus} from '@/models';
import {socketService} from '@/services/socket';

@Component({})
export default class Login extends Vue {
  private valid = true;
  private APIKeyRules: [any] = [
    (v: string) => !!v || 'L\'API Key est requise.',
  ];
  private APIKey: string = '';

  private async checkAPIKey() {
    if ((this.$refs.form as any).validate()) {
      try {
        const client = await authService.login(this.APIKey);
        localStorage.client = JSON.stringify(client);
        localStorage.token = this.APIKey;
        this.$root.$emit('login', client);
        socketService.emit('user.login', { APIKey: this.APIKey });
      } catch (e) {
        if (e.response.status === 401) {
          this.$root.$emit('showSnackbar', 'APIKey invalide.', SnackbarStatus.ERROR);
        } else {
          this.$root.$emit('showSnackbar', 'Erreur dans durant l\'authentification', SnackbarStatus.ERROR);
        }
        throw e;
      }
      this.$router.push('/jobs');
    }
  }
}
