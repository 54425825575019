











import { Component, Vue } from 'vue-property-decorator';
import {version} from '../../package.json';

@Component
export default class Footer extends Vue {
  get version() {
    if (process.env.VUE_APP_ENV === 'staging') {
      return '.staging';
    } else if (process.env.VUE_APP_ENV === 'development') {
      return '.dev';
    }
    return version;
  }
}
