







































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class ConfirmationInformationDialog extends Vue {
  @Prop({ type: Boolean })
  private value: boolean = false;

  private confirmation = false;

  @Watch('value')
  private valueWatcher(value: boolean) {
    if (!value) {
      this.confirmation = false;
    }
  }
}
