import axios, {AxiosRequestConfig, AxiosResponse, Method, ResponseType} from 'axios';

/**
 * Request class used to generate api requests
 * The class use the Builder design pattern
 */
export abstract class AbstractRequest {
  protected endpoint: string | undefined;
  protected baseURL: string | undefined;
  protected headers: object | undefined;
  protected params: any | undefined;
  protected data: object | undefined;
  protected responseType: ResponseType | undefined;
  protected method: Method | undefined;
  protected authed: boolean | undefined;

  constructor() {
    this.clearOptions();
  }

  /**
   * Make a GET request
   */
  protected async get<T= any>(): Promise<T> {
    this.method = 'GET';
    this.data = {};
    return this.request<T>();
  }

  /**
   * Make a POST request
   */
  protected async post<T= any>(): Promise<T> {
    this.method = 'POST';
    return this.request<T>();
  }

  /**
   * Make a PATCH request
   */
  protected async patch<T= any>(): Promise<T> {
    this.method = 'PATCH';
    return this.request<T>();
  }

  /**
   * Make a PATCH request
   */
  protected async put<T= any>(): Promise<T> {
    this.method = 'PUT';
    return this.request<T>();
  }

  /**
   * Make a DELETE pattern
   */
  protected async delete<T= any>(): Promise<T> {
    this.method = 'DELETE';
    return this.request<T>();
  }

  /**
   * Function used to do the real request
   */
  private async request<T= any>(): Promise<T> {
    let headers = {};

    if (this.authed) {
      const accessToken = localStorage.token;
      const bearer = `Bearer ${accessToken}`;
      const defaultAuthorizationHeader = {
        Authorization: bearer,
      };
      headers = Object.assign(defaultAuthorizationHeader, this.headers);
    }

    const options: AxiosRequestConfig = {
      url: this.endpoint,
      method: this.method,
      baseURL: this.baseURL,
      headers,
      params: this.params,
      data: this.data,
      responseType: this.responseType,
    };

    return axios.request<T>(options)
      .then((res: AxiosResponse<T>) => res.data)
      .finally(() => this.clearOptions());
  }

  private clearOptions() {
    this.baseURL = process.env.VUE_APP_BACKEND_URL;
    this.endpoint = '';
    this.headers = {};
    this.params = {};
    this.data = {};
    this.responseType = 'json';
    this.method = 'GET';
    this.authed = false;
  }
}
