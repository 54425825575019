









































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class ManualFiltersDialog extends Vue {
  @Prop({ type: Boolean })
  private value: boolean = false;

  private filterType: string | null = null;
  private filterValue: string | null = null;

  private validate(): boolean {
    return !(this.filterType && this.filterValue);
  }

  @Watch('value')
  private valueWatcher(value: boolean) {
    if (!value) {
      this.filterType = null;
      this.filterValue = null;
    }
  }
}
