export interface PaginationResponse<T> {
  items: T[];
  totalPage: number;
  totalEntries: number;
}

export interface PaginationParams {
  page: number;
  limit: number;
}

export const DEFAULT_PER_PAGE = {
  JOB: 30,
};
