







import {Component, Vue} from 'vue-property-decorator';
import {SnackbarStatus} from '@/models';

@Component
export default class Snackbar extends Vue {
  private show: boolean = false;
  private color: SnackbarStatus = SnackbarStatus.INFO;
  private message: string = '';

  private mounted() {
    this.$root.$on('showSnackbar', (message: string, status: SnackbarStatus) => {
      this.show = true;
      this.color = status || SnackbarStatus.INFO;
      this.message = message;
    });
  }
}
